<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterProgram
                    class="mr-2"
                    v-model:value="state.params.program"/>
                <FilterRegional
                    class="mr-2"
                    placeholder="Pilih Regional Toko"
                    v-model:value="state.params.region"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <FilterArea
                    class="mr-2"
                    placeholder="Pilih Area Toko"
                    v-model:value="state.params.area"
                    v-model:area="state.params.area"
                    v-model:region="state.params.region"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterPeriodeTransaksi
                    :mode="null"
                    class="mr-2"
                    status="0"
                    v-model:value="state.params.periode"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ADropdown
                        v-if="state.isUserPic">
                        <template #overlay>
                            <AMenu @click="btnTransfer">
                                <AMenuItem key="all">
                                    All Transfer
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Transfer
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isTransfer"
                            type="primary"
                            title="Transfer">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Transfer
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <ADropdown
                        v-if="state.isUserPic">
                        <template #overlay>
                            <AMenu @click="btnReject">
                                <AMenuItem key="all">
                                    All Reject
                                </AMenuItem>
                                <AMenuItem key="checked">
                                    Reject
                                </AMenuItem>
                            </AMenu>
                        </template>
                        <AButton
                            :loading="state.isReject"
                            type="danger"
                            title="Reject">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/>
                            Reject
                            <DownOutlined />
                        </AButton>
                    </ADropdown>
                    <DownloadExcel
                        :url="state.endpoint"
                        :params="state.params"
                        namefile="Transfer-Poin"
                        @errors="errorMessage"/>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="history_id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #rule="{ record }">
                    <span>
                        <a-tooltip title="Detail">
                            <a-button
                                class="button"
                                size="small"
                                @click="detailByRule(record)">
                                {{ record.rule }}
                            </a-button>
                        </a-tooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- details by rule name -->
        <DetailByRuleModal
            v-if="visibleRuleModal"
            v-model:visible="visibleRuleModal"
            v-model:item="visibleRuleItemModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { DownOutlined } from '@ant-design/icons-vue'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterToko from '@/components/filter/FilterToko'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterPeriodeTransaksi from '@/components/filter/FilterPeriodeTransaksi'
import DetailByRuleModal from './DetailByRuleModal'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { FormatCurrency } from '@/helpers/utils'

export default defineComponent({
    components: {
        DownOutlined,
        FilterProgram,
        FilterToko,
        FilterRegional,
        FilterArea,
        FilterPeriodeTransaksi,
        DetailByRuleModal,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Periode',
                    dataIndex: 'period_name',
                },
                {
                    title: 'Kode Program',
                    dataIndex: 'program_code',
                },
                {
                    title: 'Nama Program',
                    dataIndex: 'program_name',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'no_transaction',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'QTY ZAK',
                    dataIndex: 'qty_zak',
                },
                {
                    title: 'QTY TON',
                    dataIndex: 'qty_ton',
                },
                {
                    title: 'Poin',
                    dataIndex: 'points',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'point_uom_name',
                },
                {
                    title: 'Rupiah',
                    dataIndex: 'rupiah',
                    customRender: ({ text }) => FormatCurrency(text),
                },
                {
                    title: 'Nama Rule',
                    slots: { customRender: 'rule' },
                },
                {
                    title: 'Status',
                    dataIndex: 'point_status',
                },
            ],
            endpoint: '/api/loyalty-points/conversion/v2/list-transfer',
            endpoint_pic: '/api/loyalty-program-pic-akses',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isTransfer: false,
            isReject: false,
            isUserPic: false,
            params: {
                search: '',
                region: [],
                area: [],
                toko: [],
                program: [],
                periode: null,
                page: 1,
                "per-page": 10,
            },
            selectedRows: [],
            selectedRowKeys: [],
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: state.params,
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch user pic
        const fetchUserPic = () => {
            apiClient
                .get(state.endpoint_pic)
                .then(({ data }) => {
                    state.isUserPic = data
                })
        }

        // send post button action
        const sendPost = (action, field) => {

            if (state.selectedRowKeys.length === 0 && field.key == 'checked') {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            let data = {
                allData: false,
                filters: state.params,
            }

            let rowData = []
            if (field.key == 'checked') {
                state.selectedRows.forEach((item, index) => {
                    rowData.push({
                        history_id: item.history_id,
                    })
                });

                data = {
                    data: rowData,
                }
            } else {
                data.allData = true
            }

            let apiUrl = '/api/loyalty-point/reject'
            if (action == 'transfer') {
                state.isTransfer = true
                apiUrl = '/api/loyalty-point/transfer'
            } else {
                state.isReject = true
            }

            apiClient.post(apiUrl, data)
            .then(({ data }) => {
                state.selectedRowKeys = []
                fetchDataList()
                message.success('Berhasil mengirim status')
            })
            .catch(e => message.error('Gagal mengirim status!'))
            .finally(() => {
                state.isTransfer = false
                state.isReject = false
            })
        }

        // button transfer
        const btnTransfer = (item) => {
            return sendPost('transfer', item)
        }

        // button reject
        const btnReject = (item) => {
            return sendPost('reject', item)
        }

        // handle detail by rule name
        const visibleRuleModal = ref(false);
        const visibleRuleItemModal = ref(null);

        const detailByRule = (item) => {
            visibleRuleModal.value = true
            visibleRuleItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
            fetchUserPic()
        })

        return {
            btnTransfer,
            btnReject,
            onSelectChange,
            fetchDataList,
            state,
            handleTableChange,
            visibleRuleModal,
            visibleRuleItemModal,
            detailByRule,
            errorMessage,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
